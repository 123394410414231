import Header from "../../components/header";
import { Container } from "react-bootstrap";
import "./styles.scss";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section5 from "./components/section5";
import Section6 from "./components/sextion6";
import Footer from "../../components/footer";
import Section4 from "./components/section4";

function HomeScreen() {
  return (
    <div className="HomeScreen">
      <Header />
      <Container>
        <Section1 />
        <Container>
          <Section2 />
          {/* <Section4/> */}
          <Section5 />

          <Section3 />
          <Section6 />
          <div style={{ margin: "80px 0" }}></div>
        </Container>
      </Container>
      <Footer />
    </div>
  );
}

export default HomeScreen;
