import { Col, Row } from "react-bootstrap";
import backgroundImg from "../../../assets/images/background.png";
function Section1() {
  return (
    <div className="HomeScreen-Section1">
      <Row>
        <Col sm={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
          <div className="HomeScreen-Box1">
            <div className="HomeScreen-text1">life will be easy with our</div>
            <div className="HomeScreen-text2">entry managing app</div>
            <br />
            <div className="HomeScreen-text3">
              XENTRY BOOK is your all-in-one solution for efficient office
              management, designed to streamline and organize your workplace
              operations.
            </div>
            <br />
            <div className="HomeScreen-btn">Download Now</div>
          </div>
        </Col>
        <Col sm={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
          <div className="HomeScreen-Box2">
            <img src={backgroundImg} className="HomeScreen-img1" alt="bg1" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Section1;
