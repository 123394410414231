import { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeader";
import { Button, DatePicker, Input, Pagination, message } from "antd";
import LoadingBox from "../../components/loading";
import DataTable from "./dataTable";
import Forms from "./form";

import API from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";

function Offices() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const pageSize = 10;

  useEffect(() => {
    getData("", "", 1);
  }, []);

  const getData = async (qry: any, date: any, number: any) => {
    try {
      let obj: any = {
        query: qry,
        date: date,
      };
      let api = `${API.SEARCH_OFFICE}?order=DESC&page=${number}&take=${pageSize}`;
      let response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const editItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(!openForm);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    getData(search, date, page);
  };

  return (
    <div className="Offices">
      <PageHeader title={`Offices ( ${data.length} )`}>
        <div style={{ display: "flex" }}>
          <DatePicker
            style={{ width: "50%" }}
            onChange={(a: any, date: any) => {
              setDate(date);
              setPage(1);
              getData(search, date, 1);
            }}
            allowClear
          />
          &nbsp;
          <Input.Search
            placeholder="input search text"
            allowClear
            enterButton="Search"
            onSearch={(val: any) => {
              setSearch(val);
              setPage(1);
              getData(val, date, 1);
            }}
          />
          &nbsp;
          <Button type="primary" onClick={() => setOpenForm(true)}>
            + New Office
          </Button>
        </div>
      </PageHeader>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <DataTable
            data={data}
            onChange={(data: any) => editItem(data)}
            pagination={false}
          />
          <Pagination
            current={page || 1}
            pageSize={pageSize}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            defaultPageSize={pageSize || 10}
            disabled={false}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        </>
      )}
      {openForm ? (
        <Forms
          visible={openForm}
          data={selectedItem}
          onClose={() => editItem({})}
          onChange={() => getData("", "", page)}
        />
      ) : null}
    </div>
  );
}

export default Offices;
