import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./utils/protectedRoute";

import { ConfigProvider } from "antd";

import HomeScreen from "./website/homeScreen";
import ContactScreen from "./website/contactScreen";
import LoginScreen from "./website/loginScreen";
import AdminRoute from "./admin/route";
import Invoice from "./website/invoice";

function App() {
  const User = useSelector((state: any) => state.User);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000",
          fontFamily: "DMSans-Regular",
          borderRadius: 2,
        },
      }}
    >
      <Routes>
        <Route index element={<HomeScreen />} />;
        <Route path="/contact" element={<ContactScreen />} />;
        <Route path="/login" element={<LoginScreen />} />;
        <Route path="/invoice/:transactionId" element={<Invoice />} />;
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute isSignedIn={User.auth}>
              <AdminRoute />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
