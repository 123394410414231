import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import logo from "../../assets/images/logo-white.png";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { FiTwitter, FiYoutube } from "react-icons/fi";
import appStore from "../../assets/images/GroupplayStore.png";
import googleplay from "../../assets/images/app-store-google-play-seeklogo-02google play.png";
import { Button } from "antd";

function Footer() {
  return (
    <div className="Footer">
      <Row>
        <Col xs={12} sm={3} className="Footer-Box1">
          <div>
            <img src={logo} alt="" className="Footer-logo" />
          </div>
          <div style={{ paddingLeft: 10 }}>
            <div>website</div>
            <div>mail id</div>
            <div>phone</div>
            <div style={{ marginTop: "10px" }}>
              <FaFacebookSquare size={20} /> &nbsp;
              <FaInstagram size={20} /> &nbsp;
              <FiTwitter size={20} /> &nbsp;
              <FiYoutube size={20} />
            </div>
          </div>
        </Col>
        <Col className="Footer-Box2" xs={12} sm={3}>
          <div className="Footer-text1">Useful links</div> <br />
          <div>App Features</div>
          <div>About us</div>
          <div>Blog posts</div>
          <div>Pricing plans</div>
        </Col>
        <Col className="Footer-Box2" xs={12} sm={3}>
          <div className="Footer-text1">Product help</div> <br />
          <div>Privacy policy</div>
          <div>Terms & conditions</div>
          <div>Refund and Cancellation</div>
          <div>Support</div>
        </Col>
        <Col className="Footer-Box2" xs={12} sm={3}>
          <div className="Footer-text1">download app</div>
          <br />
          <img src={googleplay} alt="" className="Footer-Box-Btn1" />
          <img src={appStore} alt="" className="Footer-Box-Btn1" />
          <br /> <br />
          <Button className="Footer-Box-Btn2">Get Free Trial</Button>
        </Col>
      </Row>
      <hr />
      <div style={{ textAlign: "center", fontSize: 12 }}>
        Copyright All Rights Reserved. Design & developed by Semikolen IT
        Solutions
      </div>
    </div>
  );
}

export default Footer;
