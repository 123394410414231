import { useEffect, useState } from "react";
import logo from "../../assets/images/logo-dark.png";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Button } from "antd";
function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  return (
    <div className={`Header ${!show ? "Scrolled" : ""}`}>
      <Container>
        <div className="Header-Box">
          <div className="Header-Box1">
            <img
              src={logo}
              alt="logo"
              className="Header-logo"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="Header-Box2">
            <div className="Header-text1">Features</div>
            <div className="Header-text1">How it works</div>
            <div className="Header-text1">FAQ's</div>
            <div className="Header-text1">Contact</div>
          </div>
          <div className="Header-Box3">
            <Button onClick={() => navigate("/contact")} type="primary">
              Equiry
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Header;
