import { IoPersonCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/logo-dark.png";
import Logs from "../../assets/images/wired.gif";
import moment from "moment";
import { Button } from "antd";
import { logout } from "../../redux/slices/userSlice";
function AdminHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logouts = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <div className="AdminHeader">
      <div className="AdminHeaderBox1">
        <img src={logo} alt="logo" onClick={() => navigate("/admin/home")} />
      </div>
      <div className="AdminHeadertxt">ADMIN PANEL</div>
      <div
        className={"AdminHeaderButton"}
        onClick={() => {
          navigate("/admin/logs");
        }}
      >
        <img src={Logs} width={30} height={25} style={{objectFit:"contain"}}/>&nbsp;
        Logs
      </div>
      <div
        className={"AdminHeaderButton"}
        onClick={() => {
          navigate("/admin/enquiries");
        }}
      >
        Enquiries
      </div>
      <div className="AdminHeadertime">{moment().format("DD, MMMM YYYY")}</div>
      <div className="AdminHeaderBox2">
        <div className="AdminHeadertxt1">Admin</div>
        <Button block size="small" danger onClick={() => logouts()}>
          Logout
        </Button>
      </div>
      <div className="AdminHeaderBox3">
        <IoPersonCircle size={50} />
      </div>
    </div>
  );
}

export default AdminHeader;
