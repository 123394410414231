import { Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingBox = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  return (
    <div className="LoadingBox">
      <Space  direction="vertical">
        <Spin indicator={antIcon} />
        <br />
        <div className="LoadingBox-text">Loading...</div>
      </Space>
    </div>
  );
};

export default LoadingBox;