const API = {
  BASE_URL: "https://server.bairuhatech.com/dgymbook/",
  // BASE_URL: "http://localhost:8014/",

  LOGIN: "",

  LIST_OFFICE: "office/all",
  ADD_OFFICE: "office/create",
  UPDATE_OFFICE: "office/update",
  GET_BY_OFFICE: "office/",
  GET_OFFICE_INFO: "office/office_info/",
  SEARCH_OFFICE: "office/search_office",

  CONTACT_ALL: "contact/all",
  CONTACT_CREATE: "contact/create",

  LIST_TRANSACTIONS: "transactions/all_transactions", //?order=ASC&page=1&take=10
  LIST_SUBSCRIPTIONS: "subscriptions/by_office/", // by office id

  USERS_ALL: "users/all",
  USERS_LIST: "users/list/",
  USERS_DELETE: "users/delete/",
  USER_ADD: "users/create", // create USER or TRAINER
  ALL_USERS: "users/getAll", // for export Excel
  
  LOGGED_USERS: "log/byfilter", //?order=ASC&page=1&take=10
  LOGGED_USER_DETAILS: "log/userDetails", //?order=ASC&page=1&take=10
  
  GET_INVOICE: "transactions/findOne/",
  ALL_TRANS_BY_OFFICE: "transactions/export_excel/",
  
  PDF_GENERATE_URL: "https://pdf.taxgoglobal.com/getPdf",

  //Trainers
  // USER_ADD is used to create Trainer
  // also TRAINER_UPDATE
  LIST_TRAINERS: "users/list_trainers/",
  TRAINER_UPDATE: "users/update/",
  BLOCK_TRAINER: "users/block/",
};
export default API;
