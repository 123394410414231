import moment from "moment";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Pagination, Popconfirm, Table, Tag } from "antd";

const DataTable = (props: any) => {
  const columns = [
    {
      title: "S No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      render: (data: any) => {
        return (
          <Tag color={data?.status ? "green" : "red"}>
            {data?.status ? "Active" : "Blocked"}
          </Tag>
        );
      },
    },
    {
      title: "Admission No",
      dataIndex: "admisson_id",
      key: "admisson_id",
    },
    {
      title: "Name",
      render: (data: any) => {
        return (
          <div>
            {data?.first_name} {data?.last_name}
          </div>
        );
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Blood group",
      dataIndex: "blood",
      key: "blood",
    },
    {
      title: "Subscription",
      dataIndex: "subscription_name",
      key: "subscription_name",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Joined At",
      dataIndex: "register_date",
      key: "register_date",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      with: 5,
      render: (id: any) => (
        <div className="table-item">
          <Popconfirm
            title="Delete this User"
            description="Are you sure to delete this User?"
            onConfirm={() => props?.DeleteUser(id)}
            okText="Yes"
            cancelText="No"
          >
            <RiDeleteBin5Line size={20} color="red" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        size="small"
        columns={columns}
        pagination={false}
        dataSource={props?.data}
      />
      <Pagination
        disabled={false}
        responsive={true}
        defaultCurrent={1}
        total={props?.total || 0}
        onChange={props?.onChange}
        current={props?.current || 1}
        pageSize={props?.pageSize || 10}
        defaultPageSize={props?.pageSize || 10}
        showTotal={(total) => `Total ${total} Users`}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
    </>
  );
};

export default DataTable;
