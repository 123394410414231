import { Col, Row } from "react-bootstrap";
import gym from "../../../assets/images/usercardsGymsPhoto.png";
import dance from "../../../assets/images/usercardsdance.png";
import yoga from "../../../assets/images/usercardsyoga.png";
import fitness from "../../../assets/images/usercardsfitness.png";
function Section3() {
  return (
    <div>
      <Row>
        <Col md={3} xs={6}>
          <img src={gym} alt="" className="HomeScreen-Section3-img" />
        </Col>
        <Col md={3} xs={6}>
          <img src={dance} alt="" className="HomeScreen-Section3-img" />
        </Col>
        <Col md={3} xs={6}>
          <img src={yoga} alt="" className="HomeScreen-Section3-img" />
        </Col>
        <Col md={3} xs={6}>
          <img src={fitness} alt="" className="HomeScreen-Section3-img" />
        </Col>
      </Row>
      <br />
      <br /> <br /> <br />
    </div>
  );
}
export default Section3;
