import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import PageHeader from "../../components/pageHeader";
import { Button, message } from "antd";
import "./styles.scss";
import { IoMdCloudDownload } from "react-icons/io";
import { useParams } from "react-router-dom";
import moment from "moment";
import API from "../../config/api";
import Header from "../../components/header";

import { InvoiceTemplate } from "./components/invoiceTemplate";
import LoadingBox from "../../components/loading";
import { GET } from "../../utils/apiCalls";

function Invoice(props: any) {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response: any = await GET(
        API.GET_INVOICE + params?.transactionId,
        null
      );
      if (response?.status) {
        setData(response?.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const downLoadPdf = async (data: any) => {
    try {
      setIsLoading2(true);
      const templates = InvoiceTemplate(data);
      const templateContent = templates
        .replace(/\r\n/g, "")
        .replace(/\\"/g, '"');

      const encodedString = btoa(unescape(encodeURIComponent(templateContent)));

      const pdf_url = API.PDF_GENERATE_URL;
      const pdfData = {
        filename: "Transaction Invoice",
        html: encodedString,
        isDownload: true,
        sendEmail: false,
        type: "",
        userid: "",
      };

      const response = await fetch(pdf_url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pdfData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const pdfBlob = await response.blob();
      const url = URL.createObjectURL(pdfBlob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `Transaction_${moment(new Date()).format("DD-MM-YYYY")}`;
      a.click();

      URL.revokeObjectURL(url);
      setIsLoading2(false);
    } catch (err) {
      setIsLoading2(false);
      message.error("Failed to download PDF");
      console.error("Error:", err);
    }
  };

  return (
    <div className="Invoice">
      <Header />
      <div style={{ height: 60 }} />
      <hr />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Container>
          <PageHeader title={`Invoice`}>
            <div style={{ display: "flex" }}>
              <Button
                icon={<IoMdCloudDownload />}
                type="primary"
                onClick={() => downLoadPdf(data)}
                loading={isLoading2}
              >
                Download
              </Button>
            </div>
          </PageHeader>
          <br />
          <div
            dangerouslySetInnerHTML={{ __html: InvoiceTemplate(data) }}
          ></div>
        </Container>
      )}
      <br />
      <br />
    </div>
  );
}

export default Invoice;
