import { Table, Tag } from "antd";
import { CiEdit, CiRead } from "react-icons/ci";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function DataTable(props: any) {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Index",
      render: (item: any,record:any,index:number) => <strong>{index+1}.</strong>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (item: any) => <div>{moment(item).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "Expire Date",
      dataIndex: "end_date",
      render: (item: any) => <div>{moment(item).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item: any) => (
        <div>
          {item ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Blocked</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      with: 10,
      render: (item: any) => (
        <div className="table-item">
          <div onClick={() => navigate(`/admin/details/info/${item?.id}`)}>
            <CiRead size={25} />
          </div>
          <div onClick={() => props.onChange(item)}>
            <CiEdit size={25} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Table
      size="small"
      dataSource={props?.data}
      columns={columns}
      pagination={props?.pagination}
    />
  );
}

export default DataTable;
