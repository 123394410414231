import { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Checkbox,
  DatePicker,
  TimePicker,
  Switch,
} from "antd";
import { Col, Row } from "react-bootstrap";
import { IoRemoveCircleOutline } from "react-icons/io5";
import dayjs from "dayjs";
import API from "../../config/api";
import { POST } from "../../utils/apiCalls";

function Forms(props: any) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let url = props?.data?.id ? API.UPDATE_OFFICE : API.ADD_OFFICE;
      let obj = {
        id: props?.data?.id,
        name: values?.name,
        phone: Number(values?.phone),
        email: values?.email,
        address: values?.address,
        logo: props?.data?.logo,
        lat: Number(values?.lat),
        long: Number(values?.long),
        opening_time: values?.opening_time,
        closing_time: values?.closing_time,
        devices: values.devices,
        status: values.status,
        start_date: values?.start_date,
        end_date: values?.end_date,
        paid_amount: values?.paid_amount,
        user_limit: Number(values?.user_limit),
        prefix: values?.prefix,
        sufix: values?.sufix,

        first_name: values?.first_name,
        last_name: values?.last_name,
        password: values?.password,
      };
      let response: any = await POST(url, obj);
      if (response.status) {
        props.onChange();
        props.onClose();
        message.success("Success");
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      message.error("oops.something gone wrong.");
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`${props?.data?.id ? "Edit" : "New"} Office`}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={"60%"}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          name: props?.data?.name,
          phone: props?.data?.phone,
          email: props?.data?.email,
          address: props?.data?.address,
          lat: props?.data?.lat,
          long: props?.data?.long,
          opening_time: dayjs(props?.data?.opening_time),
          closing_time: dayjs(props?.data?.closing_time),
          start_date: dayjs(props?.data?.start_date),
          end_date: dayjs(props?.data?.end_date),
          paid_amount: props?.data?.paid_amount,
          user_limit: props?.data?.user_limit,
          prefix: props?.data?.prefix,
          sufix: props?.data?.sufix,
          biometric:
            props?.data?.biometric_type === "both"
              ? ["face", "finger"]
              : props?.data?.biometric_type
              ? [props?.data?.biometric_type]
              : [],
          status: props?.data.status ? props?.data.status : true,
          devices: props?.data.devices?.length ? props?.data.devices : [{}],

          first_name: props?.data?.first_name,
          last_name: props?.data?.last_name,
          password: props?.data?.password,
        }}
      >
        <Row>
          <Col sm={6} xs={12}>
            <div className="form-label">Office Name</div>
            <Form.Item
              rules={[{ required: true, message: "Enter Office Name" }]}
              name={"name"}
              style={{ marginTop: -8 }}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <div className="form-label">Phone Number</div>
            <Form.Item
              rules={[{ required: true, message: "Enter Phone Number" }]}
              name={"phone"}
              style={{ marginTop: -8 }}
            >
              <Input type="number" placeholder="phone" />
            </Form.Item>
            <div className="form-label">Email Address</div>
            <Form.Item
              rules={[{ required: true, message: "Enter Email Address" }]}
              name={"email"}
              style={{ marginTop: -8 }}
            >
              <Input placeholder="email" />
            </Form.Item>
            <div className="form-label">Office Address & Location</div>
            <Form.Item
              rules={[
                { required: true, message: "Enter Office Address & Location" },
              ]}
              name={"address"}
              style={{ marginTop: -8 }}
            >
              <Input.TextArea rows={4} placeholder="address" />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <Form.Item
                  rules={[{ required: true, message: "Enter Latittude" }]}
                  name={"lat"}
                  style={{ marginTop: -8 }}
                >
                  <Input placeholder="Latittude" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <Form.Item
                  rules={[{ required: true, message: "Enter Longitude" }]}
                  name={"long"}
                  style={{ marginTop: -8 }}
                >
                  <Input placeholder="Longitude" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="form-label">Opening Time</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Opening Time" }]}
                  name={"opening_time"}
                  style={{ marginTop: -8 }}
                >
                  <TimePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">Closing Time</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Closing Time" }]}
                  name={"closing_time"}
                  style={{ marginTop: -8 }}
                >
                  <TimePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={6} xs={12}>
            <div className="form-header">Subscription Details</div>
            <Row>
              <Col sm={6}>
                <div className="form-label">Start Date</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Start Date" }]}
                  name={"start_date"}
                  style={{ marginTop: -8 }}
                >
                  <DatePicker format={dateFormat} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">End Date</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter End Date" }]}
                  name={"end_date"}
                  style={{ marginTop: -8 }}
                >
                  <DatePicker format={dateFormat} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="form-label">Paid Amount</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Paid Amount" }]}
                  name={"paid_amount"}
                  style={{ marginTop: -8 }}
                >
                  <Input placeholder="paid amount" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">User Limit</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter User Limit" }]}
                  name={"user_limit"}
                  style={{ marginTop: -8 }}
                >
                  <Input type="number" placeholder="Limit" />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-header">Admin Details</div>
            <Row>
              <Col sm={6}>
                <div className="form-label">Fist Name</div>
                <Form.Item
                  rules={[
                    {
                      required: props?.data?.id ? false : true,
                      message: "Enter Fist Name",
                    },
                  ]}
                  name={"first_name"}
                  style={{ marginTop: -8 }}
                >
                  <Input
                    placeholder="Fist Name"
                    disabled={props?.data?.id ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">Last Name</div>
                <Form.Item
                  rules={[
                    {
                      required: props?.data?.id ? false : true,
                      message: "Enter Last Name",
                    },
                  ]}
                  name={"last_name"}
                  style={{ marginTop: -8 }}
                >
                  <Input
                    placeholder="Last Name"
                    disabled={props?.data?.id ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="form-label">Admin Password</div>
            <Form.Item
              rules={[
                {
                  required: props?.data?.id ? false : true,
                  message: "Enter Admin Password",
                },
              ]}
              name={"password"}
              style={{ marginTop: -8 }}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <div className="form-header">Devices Details</div>
            <Row>
              <Col sm={6}>
                <div className="form-label">Prefix</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Prefix" }]}
                  name={"prefix"}
                  style={{ marginTop: -8 }}
                >
                  <Input
                    placeholder="Eg:HIR"
                    disabled={props?.data?.id ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="form-label">Sufix Start</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Sufix Start" }]}
                  name={"sufix"}
                  style={{ marginTop: -8 }}
                >
                  <Input
                    placeholder="Eg:HIR-1000"
                    disabled={props?.data?.id ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ marginTop: -10 }} />
            <hr />
            <Form.List name="devices">
              {(fields, { add, remove }) => (
                <>
                  <Row>
                    <Col sm={5}>
                      <div className="form-label">Company Code</div>
                    </Col>
                    <Col sm={5}>
                      <div className="form-label">Serial Number</div>
                    </Col>
                  </Row>
                  {fields.map((field) => (
                    <>
                      <Row>
                        <Col sm={5}>
                          <Form.Item
                            rules={[
                              { required: true, message: "Enter Device Name" },
                            ]}
                            name={[field.name, "device_name"]}
                            style={{ marginTop: -8 }}
                          >
                            <Input placeholder="Device Name" />
                          </Form.Item>
                        </Col>
                        <Col sm={5}>
                          <Form.Item
                            rules={[
                              { required: true, message: "Enter Device ID" },
                            ]}
                            name={[field.name, "device_id"]}
                            style={{ marginTop: -8 }}
                          >
                            <Input placeholder="Device ID" />
                          </Form.Item>
                        </Col>
                        <Col sm={2}>
                          <Form.Item style={{ marginTop: -8 }}>
                            <Button onClick={() => remove(field.name)} block>
                              <IoRemoveCircleOutline size={20} color="red" />
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: -8 }}>
                        <Col sm={5}>
                          <div className="form-label">Gates</div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: -8 }}>
                        <Col sm={6}>
                          <Form.Item
                            name={[field.name, "gates"]}
                            style={{ marginTop: -8 }}
                          >
                            <Checkbox.Group onChange={(v) => console.log(v)}>
                              <Checkbox value={"face"}>Face</Checkbox>
                              <Checkbox value={"finger"}>Finger</Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Row>
                    <Col sm={6}></Col>
                    <Col sm={6}>
                      <Button block onClick={() => add()}>
                        Add Device +
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <br />
        <Row>
          <Col sm={6}>
            <div className="form-label">Application Status</div>
            <Form.Item
              rules={[{ required: true, message: "Enter " }]}
              name={"status"}
              valuePropName={props?.data.status ? "checked" : "null"}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <br />
            <Row>
              <Col sm={6}>
                <Button block size="large" onClick={() => props.onClose()}>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  type="primary"
                  loading={loading}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Forms;
