import "./styles.scss";
import AdminHeader from "./header";
import { Container } from "react-bootstrap";

import Offices from "../offices";
import { Route, Routes } from "react-router-dom";
import Enquiries from "../Enquiries";
import Details from "../offices/details";
import Logs from "../Logs";
function AdminRoute() {
  return (
    <div className="AdminRoute">
      <AdminHeader />
      <div className="AdminRoute-box">
        <Container>
          <Routes>
            <Route path="/home" element={<Offices />} />
            <Route path="/details/:tab/:id" element={<Details />} />
            <Route path="/enquiries" element={<Enquiries />} />;
            <Route path="/logs" element={<Logs />} />;
          </Routes>
        </Container>
      </div>
    </div>
  );
}

export default AdminRoute;
