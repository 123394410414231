import { Col, Row } from "react-bootstrap";
import section2Img from "../../../assets/images/phoneOnRock.png";
import { LiaIdCard } from "react-icons/lia";
import { LiaClipboardListSolid } from "react-icons/lia";
import { MdCreditScore } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { FaWhatsapp } from "react-icons/fa";
import { TfiReceipt } from "react-icons/tfi";
function section2() {
  return (
    <div>
      <Row>
        <Col sm={6} xs={12} style={{ margin: 0, padding: 0 }}>
          <img src={section2Img} alt="" className="HomeScreen-Section2-img" />
        </Col>
        <Col sm={6} xs={12} style={{ margin: 0, padding: 0 }}>
          <div className="HomeScreen-Section2-Box2">
            <div className="HomeScreen-section2-text1">
              Packed full of features
            </div>
            <Row>
              <Col sm={6} xs={6}>
                <div className="HomeScreen-icon-container">
                  <LiaIdCard size={40} />
                </div>
                <div className="HomeScreen-section2-text2">
                  Membership Management
                </div>
                <div className="HomeScreen-section2-text3">
                  Get a glance and manage all the members in an efficient,
                  intelligent and curated way
                </div>
              </Col>
              <Col sm={6} xs={6}>
                <div className="HomeScreen-icon-container">
                  <LiaClipboardListSolid size={40} />
                </div>
                <div className="HomeScreen-section2-text2">plan Management</div>
                <div className="HomeScreen-section2-text3">
                  Integrate and refine the subscription plans according to the
                  needs of your clients
                </div>
              </Col>
              <Col sm={6} xs={6}>
                <div className="HomeScreen-icon-container">
                  <MdCreditScore size={40} />
                </div>
                <div className="HomeScreen-section2-text2">
                  Membership Management
                </div>
                <div className="HomeScreen-section2-text3">
                  Get a glance and manage all the members in an efficient,
                  intelligent and curated way
                </div>
              </Col>
              <Col sm={6} xs={6}>
                <div className="HomeScreen-icon-container">
                  <TbReportAnalytics size={40} />
                </div>
                <div className="HomeScreen-section2-text2">
                  Accurate Reporting
                </div>
                <div className="HomeScreen-section2-text3">
                  Track your business performance with robust metrics and
                  insights right in the app
                </div>
              </Col>
              <Col sm={6} xs={6}>
                <div className="HomeScreen-icon-container">
                  <FaWhatsapp size={40} />
                </div>
                <div className="HomeScreen-section2-text2">
                  Whatsapp Auto Reminder
                </div>
                <div className="HomeScreen-section2-text3">
                  Zero overdue payments with auto membership renewal reminders
                  through WhatsApp
                </div>
              </Col>
              <Col sm={6} xs={6}>
                <div className="HomeScreen-icon-container">
                  <TfiReceipt size={40} />
                </div>
                <div className="HomeScreen-section2-text2">Digital Receipt</div>
                <div className="HomeScreen-section2-text3">
                  Share payment receipts digitally with the client right from
                  the app for accountability
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default section2;
