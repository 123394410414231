import { Pagination, Table } from "antd";
import moment from "moment";

const DataTable = (props: any) => {
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Duration Type",
      dataIndex: "duration_type",
      key: "duration_type",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
  ];

  return (
    <div>
      <Table
        size="small"
        dataSource={props?.data}
        pagination={false}
        columns={columns}
      />
      <Pagination
        disabled={false}
        responsive={true}
        defaultCurrent={1}
        total={props?.total || 0}
        onChange={props?.onChange}
        current={props?.current || 1}
        pageSize={props?.pageSize || 10}
        defaultPageSize={props?.pageSize || 10}
        style={{ marginTop: "20px", textAlign: "center" }}
        showTotal={(total) => `Total ${total} Subscriptions`}
      />
      <br />
    </div>
  );
};

export default DataTable;
